@import url('https://fonts.googleapis.com/css?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond&display=swap');

.App {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.4vw;
  font-weight: 900;
  color: #885300;
}

.navbar {
  height: 2.45em;
  width: 100%;
  font-size: 1.5em;
  position: fixed;
  top: 0;
  transition: 0.7s;
  z-index: 99;
}

.navbar--hidden {
  transition: 0.7s;
  opacity: 0;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-inline-start: 0;
  z-index: 99;
}


nav a {
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

nav a:hover, .up-arrow:hover {
  color: #b4c87c;
  text-shadow: 0px 0px 6px;
}

#contact a:hover  {
  text-shadow: 0px 0px 6px;
}

#logo {
  width: 7em;
  color: #b4c87c;
  box-shadow: inset 0px 0px 160px 11px;
  border-radius: 100%;
  transition: all 0.4s ease-in-out;
}

#logo:hover {
  color: #b4c87c;
  box-shadow: inset 0px 0px 160px 44px;
}

main {
  max-width: 100%;
  height: 100vh;
}

#image, #image-menu {
  height: 100vh;
}
#image img, #image-menu img {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

#overlay-text {
  margin-top:1em;
  position: absolute;
  color: white;
  font-size: 3em;
  font-weight: 900;
}

#overlay-text2 {
  width: 70%;
  padding: 0.3em;
  margin: 0.3em;
  border-radius: 15px;
  background-color: #b4c87c;
  position: absolute;
  color: white;
  font-size: 1em;
  font-weight: 900;
}


#overlay-text-menu {
  position: absolute;
  color: white;
  font-size: 3.8em;
  font-weight: 900;
}

#about {
  height: auto;
  min-height: 100vh;
}

#overlay-about {
  width: 60%;
  padding: 2em;
  margin: 2em;
  font-size: 1.3em;
  border: 10px double;
  ul {
  }
}

#slider {
  display: block;
  img {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: right;
  }
}


.slick-dots {
  bottom: 25px !important;
}

.slick-next {
  right: 35px !important;
  z-index: 99;
}

.slick-prev {
  left: 35px !important;
  z-index: 99;
}

.slick-next::before, .slick-prev::before {
  font-size: 33px !important;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
}

section h1 {
  text-align: center;
  text-decoration: underline;
}

.grid-container {
  display: grid;
  grid-template-columns: 70% 30%;
  overflow: hidden;
  width: 60%;
  font-size: 1.2em;
  margin: 1em;
}

.grid-item {
  text-align: right;
  padding: 1em;
  border-bottom: 2px double;
}

.grid-itemlong {
  text-align: left;
  padding: 1em;
  border-bottom: 2px double;
}



#menu {
  margin: 0 auto;
  flex-direction: column;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1em;
  padding-top: 2em;
  padding-bottom: 2em;
  .grid-container {
    border: 10px double;
    padding: 2em;   
  }
}

#menu h1 {
  font-size: 2em;
  text-align: center;
}

#menu span {
  font-size: 1.7em;
}
#contact {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 auto;
  flex-direction: column;
  font-size: 1.8em;
  width: 70%;
  height: 100vh;
  line-height: 1.7em;
}

#contact ul  {
  list-style-type: none;
  padding: 0;
  height: 40vh;
  font-size: 0.8em;
  text-align: left;
}

#contact a {
  color: #885300;
  text-decoration: none;
}

#contact h1 {
  text-align: left;
}

#social {
  display: flex;
  flex-direction: column;
}

.social-logo  {
  font-size: 1.5em;
  color: #885300;
  margin-right: 0.4em;
}

.up-arrow {
  position: fixed;
  bottom: 6px;
  right: 6px;
  float: right;
  color: #885300;
  font-size: 4em;
}

@media screen and (max-width: 820px) {
  .navbar {
    font-size: 2em;
  } 
  #image, #image-menu {
    height: 100vh;
    flex-direction: column;
    flex-flow: row wrap;
  }
  #image img {
    height: 100vh;
    background-size: cover;
    background-attachment: inherit;
    background-position: right;                
  }
  #image-menu img {
    background-size: cover;
    background-attachment: inherit;
    background-position: center;                
  }
  #overlay-about {
    position: relative;
    bottom: 0;
    width: 100%;
    font-size: 3em;
    border-radius: 0;
  }
  .slick-arrow {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    font-size: 4em;
  }
  nav ul {
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    li {
      padding: 0.2em;
    }
  }
  #logo {
    width: 7em;
  }
  #overlay-text {
    font-size: 4em;
  }
  #overlay-text2 {
    font-size: 3em;
  }
  #overlay-text-menu {
    font-size: 7em;
  }
  #menu {
    font-size: 2em;
  }
  #contact {
    width: auto;
    min-height: 100vh;
    height: auto;
    font-size: 4.5em;
    padding: 1em;
    display: flex;
    justify-content: center;
  }
  #contact ul {
    height: auto;
    padding: 1em;
  }
  .grid-container {
    width: 70%;
  }
  .up-arrow {
    font-size: 10em;
  }
}
